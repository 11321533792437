<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'联网单位详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-tabs
        v-model="active"
        sticky
        @change="tabChange"
      >
        <van-tab title="基本信息">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBox"
              @scroll="onScroll"
            >
              <van-collapse
                v-model="activeNames"
                accordion
              >
                <van-collapse-item
                  title="基本信息"
                  name="1"
                >
                  <van-cell
                    center
                    title="合同号:"
                  >
                    <template #default>
                      <p
                        :style="{ color: '#1990ff' }"
                        v-if="CompanyInfo?.agreementList?.length === 1
                        "
                        @click="agreementClick(CompanyInfo.agreementList[0].id)"
                      >
                        {{ CompanyInfo.agreementList[0].name }}
                      </p>
                      <p
                        :style="{ color: '#1990ff' }"
                        v-else-if="CompanyInfo?.agreementList?.length > 1
                        "
                      >
                        <van-popover
                          v-model="showPopover"
                          trigger="click"
                          :actions="CompanyInfo.agreementList.map(item => { return { text: item.name, className: 'clickText', id: item.id } })"
                          @select="onSelect"
                        >
                          <template #reference>
                            点击查看
                          </template>
                        </van-popover>
                      </p>
                      <p v-else>--</p>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="单位编号:"
                    :value="CompanyInfo.code || '--'"
                  />
                  <van-cell
                    center
                    title="单位名称:"
                    :value="CompanyInfo.name || '--'"
                  />
                  <van-cell
                    center
                    title="上级单位:"
                  >
                    <template #default>
                      <p
                        :style="{ color: '#1990ff' }"
                        v-if="CompanyInfo?.parentCompany?.name"
                        @click="parentCompanyClick(CompanyInfo.parentCompany.id)"
                      >
                        {{ CompanyInfo.parentCompany.name }}
                      </p>
                      <p v-else>--</p>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="单位状态:"
                  >
                    <template #default>
                      <span
                        style="color: #00a854"
                        v-if="CompanyInfo.status === 0"
                      >在营</span>
                      <span
                        style="color: #d9001b"
                        v-else-if="CompanyInfo.status === 1"
                      >停运</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="单位性质:"
                    :value="CompanyInfo.nature || '--'"
                  />
                  <van-cell
                    center
                    title="所属行业:"
                    :value="CompanyInfo.industry || '--'"
                  />
                  <van-cell
                    center
                    title="单位规模:"
                    :value="CompanyInfo.scale || '--'"
                  />
                  <van-cell
                    center
                    title="省/市/区:"
                    :value="(CompanyInfo?.location?.province
                      ? CompanyInfo.location.province
                      : '') +
                      (CompanyInfo?.location?.city
                        ? '/' + CompanyInfo.location.city
                        : '') +
                      (CompanyInfo?.location?.district
                        ? '/' + CompanyInfo.location.district
                        : '') || '--'
                      "
                  />
                  <van-cell
                    center
                    title="单位地址:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="addressHandler(CompanyInfo.location.address)"
                        v-if="CompanyInfo?.location?.address
                        "
                        class="ellipsis"
                      >
                        {{ CompanyInfo.location.address }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="邮政编码:"
                    :value="CompanyInfo.postCode || '--'"
                  />
                  <van-cell
                    title="联系电话:"
                    center
                  >
                    <template #default>
                      <p
                        :style="{ color: '#1990ff' }"
                        v-if="CompanyInfo.telephone"
                        @click="callOut(CompanyInfo.telephone)"
                      >
                        {{ CompanyInfo.telephone }}
                      </p>
                      <p v-else>--</p>
                    </template>
                  </van-cell>
                  <van-cell
                    title="分机号:"
                    center
                  >
                    <template #default>
                      <p
                        :style="{ color: '#1990ff' }"
                        v-if="CompanyInfo.extNumber"
                        @click="
                          callOutExtNumber(
                            CompanyInfo.telephone,
                            CompanyInfo.extNumber
                          )
                          "
                      >
                        {{ CompanyInfo.extNumber }}
                      </p>
                      <p v-else>--</p>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="单位传真:"
                    :value="CompanyInfo.fax || '--'"
                  />
                  <van-cell
                    center
                    title="电子邮箱:"
                    :value="CompanyInfo.email || '--'"
                  />
                  <van-cell
                    center
                    title="更新时间:"
                    :value="CompanyInfo.updateDatetime | dateFormat"
                  />
                  <userJump
                    title="更新人:"
                    :userList="CompanyInfo?.updateBy"
                  ></userJump>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="联网信息">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxYyInfo"
              @scroll="onScrollYyInfo"
            >
              <van-collapse
                v-model="activeIntNames"
                accordion
              >
                <van-collapse-item
                  title="联网信息"
                  name="1"
                >
                  <userJump
                    title="区域负责人:"
                    :userList="CompanyInfo?.network?.regionDirector"
                  ></userJump>
                  <van-cell
                    center
                    title="联网单位:"
                  >
                    <template #default>
                      <span
                        style="color: #d9001b"
                        v-if="CompanyInfo?.network?.network === 0
                        "
                      >是</span>
                      <span
                        style="color: #00a854"
                        v-else-if="CompanyInfo?.network?.network === 1
                        "
                      >否</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    v-if="CompanyInfo?.network?.network === 0"
                    center
                    title="物联情况:"
                    :value="CompanyInfo?.network?.internet ||
                      '--'
                      "
                  />
                  <van-cell
                    v-if="CompanyInfo?.network?.network === 0"
                    center
                    title="重点单位:"
                  >
                    <template #default>
                      <span
                        style="color: #d9001b"
                        v-if="CompanyInfo?.network?.emphasis === 0
                        "
                      >是</span>
                      <span
                        style="color: #00a854"
                        v-else-if="CompanyInfo?.network?.emphasis === 1
                        "
                      >否</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    v-if="CompanyInfo?.network?.network === 0"
                    center
                    title="高危单位:"
                  >
                    <template #default>
                      <span
                        style="color: #d9001b"
                        v-if="CompanyInfo?.network?.highRisk === 0
                        "
                      >是</span>
                      <span
                        style="color: #00a854"
                        v-else-if="CompanyInfo?.network?.highRisk === 1
                        "
                      >否</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    v-if="CompanyInfo?.network?.network === 0"
                    center
                    title="付费情况:"
                  >
                    <template #default>
                      <span
                        style="color: #d9001b"
                        v-if="CompanyInfo?.network?.payment === 0
                        "
                      >是</span>
                      <span
                        style="color: #00a854"
                        v-else-if="CompanyInfo?.network?.payment === 1
                        "
                      >否</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="更新时间:"
                    :value="CompanyInfo?.network?.updateDatetime | dateFormat
                      "
                  />
                  <userJump
                    title="更新人:"
                    :userList="CompanyInfo?.network?.updateBy"
                  ></userJump>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab title="建筑信息">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxJzInfo"
              @scroll="onScrollJzInfo"
            >
              <div
                class="searchBar"
                v-if="CompanyInfo?.building"
              >
                <span class="label">共{{ CompanyInfo.building.length }}条</span>
              </div>
              <div v-if="CompanyInfo?.building">
                <van-cell-group>
                  <van-cell
                    :label="`建筑地址：${item.address}`"
                    v-for="(item, index) in CompanyInfo.building "
                    :key="index"
                    @click="buildAddressHandler(item.address, item.name)"
                    title-style="width:100%"
                  >
                    <template #title>
                      <div>
                        <span :class="{ 'custom-title': true, buildtext: item.buildIsSuperHighRise === 1, ellipsis: true }">{{
                          item.name }}</span>
                        <van-tag
                          type="danger"
                          v-if="item.buildIsSuperHighRise === 1"
                        >超高</van-tag>
                      </div>
                    </template>
                  </van-cell>
                </van-cell-group>
              </div>
              <div
                class="empty"
                v-else
              >
                <van-empty
                  image="search"
                  description="暂无相关建筑信息"
                />
              </div>
            </div>
          </template>
        </van-tab>
        <van-tab title="开通记录">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxXfInfo"
              @scroll="onScrollXfInfo"
            >
              <van-collapse
                v-model="activeOpeNames"
                accordion
                v-if="CompanyInfo.openLog"
              >
                <van-collapse-item
                  :title="item.openDatetime | changeCardTimeFormat"
                  :name="item.id || '0'"
                  v-for="( item, index ) in CompanyInfo.openLog "
                  :key="index"
                  :id="item.id"
                >
                  <van-cell
                    center
                    title="单位编号:"
                    :value="item.code || '--'"
                  />
                  <van-cell
                    center
                    title="管辖支队:"
                    :value="item.detachment || '--'"
                  />
                  <van-cell
                    center
                    title="图纸资料:"
                    :value="item.drawingData || '--'"
                  />
                  <van-cell
                    center
                    title="图纸日期:"
                    :value="item.drawingDataDatetime | openYmdFormat"
                  />
                  <van-cell
                    center
                    title="开通类型:"
                    :value="item.openType || '--'"
                  />
                  <van-cell
                    center
                    title="开通日期:"
                    :value="item.openDatetime | openYmdFormat"
                  />
                  <userJump
                    title="开通人员:"
                    :userList="item.openBy"
                  ></userJump>
                  <van-cell
                    center
                    title="更新时间:"
                    :value="item.updateDatetime | dateFormat"
                  />
                  <userJump
                    title="更新人:"
                    :userList="item.updateBy"
                  ></userJump>
                </van-collapse-item>
              </van-collapse>
              <div
                class="empty"
                v-else
              >
                <van-empty
                  image="search"
                  description="暂无相关开通记录"
                />
              </div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getNetworkCompanyInfo } from '@/api/networkCompany'
import dayjs from 'dayjs'
import { Dialog } from 'vant'
import wx from 'weixin-js-sdk'
import index from '@/mixins'

export default {
  name: 'companyAddressInfo',
  mixins: [index],
  data() {
    return {
      activeNames: '1',
      activeIntNames: '1',
      activeOpeNames: '0',
      CompanyInfo: {},
      loadingShow: false,
      active: 0,
      top: 0,
      topYyInfo: 0,
      topXfInfo: 0,
      topJzInfo: 0,
      showPopover: false
    }
  },
  activated() {
    switch (this.active) {
      case 0:
        if (this.$refs.scrollBox) {
          this.$refs.scrollBox.scrollTop = this.top
        }
        break
      case 1:
        if (this.$refs.scrollBoxYyInfo) {
          this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
        }
        break
      case 2:
        if (this.$refs.scrollBoxJzInfo) {
          this.$refs.scrollBoxJzInfo.scrollTop = this.topJzInfo
        }
        break
      case 3:
        if (this.$refs.scrollBoxXfInfo) {
          this.$refs.scrollBoxXfInfo.scrollTop = this.topXfInfo
        }
        break
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.path.includes('/workTable/companyResult') ||
      from.path.includes('/workTable/companyAddressBook') ||
      from.path.includes('/workTable/companyAddAddressBook') ||
      from.path.includes('/workTable/companyAddResult') ||
      from.path.includes('message/systemMessage/0') ||
      to.params.Refresh
    ) {
      next(async vm => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        vm.getNetworkCompanyInfo(vm.$route.params.id)
      })
    } else {
      next(async vm => {
        if (JSON.stringify(vm.CompanyInfo) === '{}') {
          Object.assign(vm.$data, vm.$options.data.call(vm))
          vm.getNetworkCompanyInfo(vm.$route.params.id)
        }
      })
    }
  },
  methods: {
    // 获取联网单位详情
    async getNetworkCompanyInfo(id) {
      this.loadingShow = true
      try {
        const { data } = await getNetworkCompanyInfo(id)
        if (data.data) {
          this.CompanyInfo = data.data
          this.active = 0
          if (data.data.openLog.length) {
            if (!data.data.openLog[0].id) {
              this.activeOpeNames = '0'
            } else {
              this.activeOpeNames = data.data.openLog[0].id
            }
          }
          if (this.$route.query.type === '2') {
            this.active = 3
            if (this.$route.query.openId && data.data.openLog?.map(item => item.id).includes(this.$route.query.openId)) {
              this.activeOpeNames = this.$route.query.openId
              this.$nextTick(() => {
                document
                  .getElementById(this.$route.query.openId)
                  .scrollIntoView()
              })
            }
          }
          wx.onMenuShareAppMessage({
            title: this.CompanyInfo.name, // 分享标题
            desc: `单位编号:${this.CompanyInfo.code ? this.CompanyInfo.code : '--'
              }\n所属区县:${this.CompanyInfo.districtName
                ? this.CompanyInfo.districtName
                : '--'
              }\n开通日期:${this.CompanyInfo.openDatetime
                ? dayjs(this.CompanyInfo.openDatetime).format('YYYY-MM-DD')
                : '--'
              }`, // 分享描述
            link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
            imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
            enableIdTrans: 1, // 是否开启id转译，不填默认为0
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            error: function (res) {
              alert('暂无分享权限')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
          this.loadingShow = false
        } else {
          this.loadingShow = false
          if (data?.status === 'E00030007') {
            this.$toast.fail({
              message: '暂无相应权限,请联系管理员!',
              duration: 500,
              onOpened: () => {
                this.$router.replace('/')
              }
            })
            return
          }
          this.$toast.fail({
            message: '该联网单位已被删除!!',
            duration: 500
          })
        }
      } catch (error) {
        this.loadingShow = false
        if (error?.response?.status === 401) {
          return
        }
        if (error?.message.includes('timeout')) {
          this.$toast.fail({
            message: '网络不好，请稍后再试!',
            duration: 500
          })
          return
        }
        if (error?.response?.data?.status === 'E00030007') {
          this.$toast.fail({
            message: '暂无相应权限,请联系管理员!',
            duration: 500,
            onOpened: () => {
              this.$router.replace('/')
            }
          })
          return
        }
        this.$toast.fail({
          message: '当前网络异常，请稍后再试!!',
          duration: 500
        })
      }
    },
    // 呼叫手机
    callOut(val) {
      Dialog.confirm({
        title: '信息确认',
        message: `确认呼叫${val}?`
      })
        .then(() => {
          window.location.href = `tel:${val}`
        })
        .catch(() => {
          // on cancel
        })
    },
    // 呼叫分机号
    callOutExtNumber(val, num) {
      Dialog.confirm({
        title: '信息确认',
        message: `确认呼叫${val}-${num}?`
      })
        .then(() => {
          window.location.href = `tel:${val},${num}`
        })
        .catch(() => {
          // on cancel
        })
    },
    // 报废原因弹框
    scrapReasonHandler(text) {
      Dialog.alert({
        title: '不合格原因',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 单位地址弹框
    addressHandler(text) {
      this.addressClickTo(
        this.CompanyInfo.location.province +
        this.CompanyInfo.location.city +
        this.CompanyInfo.location.district +
        this.CompanyInfo.location.address,
        this.CompanyInfo.name
      )
      Dialog.confirm({
        title: '单位地址',
        message: text,
        confirmButtonColor: '#1989FA',
        confirmButtonText: '去定位',
        beforeClose: this.beforeClose
      })
        .then(() => {
          // on close
        })
        .catch(() => {
          // on cancel
        })
    },
    // 建筑地址弹框
    buildAddressHandler(text, name) {
      this.addressClickTo(
        text,
        name
      )
      Dialog.confirm({
        title: '建筑地址',
        message: text,
        confirmButtonColor: '#1989FA',
        confirmButtonText: '去定位',
        beforeClose: this.beforeClose
      })
        .then(() => {
          // on close
        })
        .catch(() => {
          // on cancel
        })
    },
    // 上级单位跳转
    async parentCompanyClick(id) {
      if (id) {
        this.$router.replace(`/workTable/companyAddressInfo/${id}`)
        this.activeNames = '1'
        this.activeIntNames = '1'
        this.activeOpeNames = '0'
        this.CompanyInfo = {}
        this.top = 0
        this.topYyInfo = 0
        this.topXfInfo = 0
        await this.getNetworkCompanyInfo(id)
        switch (this.active) {
          case 0:
            if (this.$refs.scrollBox) {
              this.$nextTick(() => {
                this.$refs.scrollBox.scrollTop = this.top
              })
            }
            break
          case 1:
            if (this.$refs.scrollBoxYyInfo) {
              this.$nextTick(() => {
                this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
              })
            }
            break
          case 2:
            if (this.$refs.scrollBoxJzInfo) {
              this.$nextTick(() => {
                this.$refs.scrollBoxJzInfo.scrollTop = this.topJzInfo
              })
            }
            break
          case 3:
            if (this.$refs.scrollBoxXfInfo) {
              this.$nextTick(() => {
                this.$refs.scrollBoxXfInfo.scrollTop = this.topXfInfo
              })
            }
            break
        }
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 合同跳转
    agreementClick(id) {
      if (id) {
        this.$router.push({
          name: 'agreementAddressInfo',
          params: {
            Refresh: true,
            id: id
          }
        })
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 单位基本信息内容滚动
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 单位联网信息滚动
    onScrollYyInfo(e) {
      this.topYyInfo = e.target.scrollTop
    },
    // 单位建筑信息滚动
    onScrollJzInfo(e) {
      this.topJzInfo = e.target.scrollTop
    },
    // 单位开通记录滚动
    onScrollXfInfo(e) {
      this.topXfInfo = e.target.scrollTop
    },
    // tabs标签切换
    tabChange(name) {
      switch (name) {
        case 0:
          if (this.$refs.scrollBox) {
            this.$nextTick(() => {
              this.$refs.scrollBox.scrollTop = this.top
            })
          }
          break
        case 1:
          if (this.$refs.scrollBoxYyInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
            })
          }
          break
        case 2:
          if (this.$refs.scrollBoxJzInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxJzInfo.scrollTop = this.topJzInfo
            })
          }
          break
        case 3:
          if (this.$refs.scrollBoxXfInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxXfInfo.scrollTop = this.topXfInfo
            })
          }
          break
      }
    },
    // 合同号选择
    onSelect(action) {
      if (action.id) {
        this.$router.push({
          name: 'agreementAddressInfo',
          params: {
            Refresh: true,
            id: action.id
          }
        })
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    }
  },
  filters: {
    // 开通记录标题格式化
    changeCardTimeFormat(val) {
      if (val) {
        return '开通记录 - ' + dayjs(val).format('YYYYMMDD')
      } else {
        return '开通记录'
      }
    }
  }
  // watch: {
  //   $route: {
  //     handler (val, oldVal) {
  //       if (val.path.includes('/workTable/companyAddressInfo')) {
  //         this.getNetworkCompanyInfo(val.params.id)
  //       }
  //     },
  //     immediate: true
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {

    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .custom-title {
      margin-right: 4px;
      vertical-align: middle;
      display: inline-block;

      &.buildtext {
        width: auto;
        max-width: calc(100% - 36px);

      }
    }

    .searchBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 24px;
      padding-bottom: 5px;
      background-color: #eeeeee;

      .label {
        font-size: 12px;
        color: #aaaaaa;
        padding-top: 5px;
      }

      .myIcon {
        width: 20px;
        height: 20px;
      }
    }

    .empty {
      position: relative;
      height: 100%;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: hidden;

      .van-tabs__line {
        background-color: #1989fa;
      }

      .van-sticky--fixed {
        top: 46px;
      }

      >.van-tabs {
        height: 100%;

        >.van-tabs__content {
          height: calc(100% - 44px);
          overflow: hidden;

          >.van-tab__pane {
            height: 100%;

            .scrollBox {
              height: 100%;
              overflow: auto;
            }
          }

          .van-collapse {
            background-color: transparent;

            .van-collapse-item {
              background-color: transparent;

              .van-collapse-item__title {
                background-color: white;
                font-weight: 700;
              }

              .van-collapse-item__wrapper {
                background-color: transparent;

                .van-collapse-item__content {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
