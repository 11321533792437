import http from '@/units/request'
import axios from 'axios'
let cancelList = null
/** *
 * 获取联网单位列表
 * ***/
export const getNetworkCompanyList = (data) => {
  if (cancelList !== null && data['page.current'] === 1) {
    cancelList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/is-company/network/v2/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      cancelList = c
    })
  })
}
/** *
 * 获取联网单位详情
 * ***/
export const getNetworkCompanyInfo = (companyId) => {
  return http({
    url: `/is-company/network/v3/info/${companyId}`,
    method: 'get'
  })
}
// /** *
//  * 获取短信模板
//  * ***/
// export const getMessageModel = () => {
//   return http({
//     url: '/is-oauth/message/template-message/v1/sms-list-by-referencelocation/1',
//     method: 'get'
//   })
// }
// /** *
//  * 获取替换人员
//  * ***/
// export const getUserMobileList = () => {
//   return http({
//     url: '/is-oauth/system/base/v1/get-user-mobile-list-by-name?departmentCode=WLWBZ,YWB',
//     method: 'get'
//   })
// }
